import { Box, Center, Image } from '@chakra-ui/react';
import React from 'react';
import Banner from 'components/landing-page/banner';
import HowItWorks from 'components/landing-page/howItWorks';
import FAQs from 'components/landing-page/faqs';
import DownloadSession from 'components/landing-page/download-session';
import Contact from 'components/landing-page/contact';
import Footer from 'components/landing-page/footer';
import mixpanel from 'mixpanel-browser';

function index() {
  mixpanel.init('815031d1a5265c6405382f88dd96571b', {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
  });
  return (
    <div>
      <Banner mixpanel={mixpanel} />
      {/* <Box
        bgImage="url('/assets/images/background-landing-page.jpg')"
        bgRepeat="repeat"
        paddingBottom="50px"
      > */}
      {/* <HowItWorks /> */}
      {/* <FAQs mixpanel={mixpanel} />
        <DownloadSession mixpanel={mixpanel} />
        <Contact mixpanel={mixpanel} /> */}
      {/* </Box> */}
      <Box
        display={{ base: 'block', md: 'none' }}
        alignItems="center"
        width="100%"
        justifyContent="center"
        marginTop="30px"
      >
        <Center>
          <Image width={340} src="/assets/images/demo-iphone.png" alt="demo" />
        </Center>
      </Box>
      <Footer />
    </div>
  );
}

export default index;
