import {
  Box,
  Flex,
  Text,
  Image,
  Stack,
  Button,
  Heading,
  Container,
  Center,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { Header } from 'components/Common/Brand';
import Logo from 'components/Common/Logo';
import React from 'react';

function Banner({ mixpanel }: any) {
  return (
    <Box
      backgroundImage={[
        "url('/assets/images/header-mobile.png')",
        "url('/assets/images/header-mobile.png')",
        "url('/assets/images/header.png')",
      ]}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      height={['700px', '700px', '982px']}
      paddingY={['20px', '20px', '40px']}
      paddingX={['20px', '20px', '40px']}
      flex={1}
      justifyContent="center"
    >
      <Container maxW="2150px">
        <Logo />
        <Flex direction="row" justify="space-between">
          <Box>
            <Header
              size="h1"
              color="white"
              fontSize={['80px', '80px', '120px']}
              zIndex={1}
              marginTop="40px"
            >
              Delivery
              <br />
              Fees
              <br />
              <Box color="orange.1" display="inline">
                Suck.
              </Box>
            </Header>
            <Text
              marginTop="25px"
              color={'#FFF8F5'}
              fontSize={['20px', '20px', '30px']}
              fontWeight="light"
            >
              Use{' '}
              <Text
                display={'inline-block'}
                fontWeight="extrabold"
                color="#FF5714"
              >
                Ekatree
              </Text>{' '}
              and never pay them again.
            </Text>
          </Box>
          <Box>
            <Image
              src="/assets/images/app-demo.png"
              alt="logo"
              display={{ base: 'none', md: 'block' }}
              objectFit="contain"
              width={'560px'}
              height={['587px', '587px', '687px']}
            />
          </Box>
        </Flex>
        <Box
          marginTop={['0px', '50px', '0px']}
          position={['relative', 'relative', 'absolute']}
          top={[0, 0, 600]}
        >
          <Text marginBottom="15px" color={'#FFF8F5'}>
            Order via{' '}
            <Text
              fontSize={20}
              display={'inline-block'}
              fontWeight="extrabold"
              color="#FF5714"
            >
              Phone
            </Text>{' '}
            or{' '}
            <Text
              fontSize={20}
              display={'inline-block'}
              fontWeight="extrabold"
              color="#FF5714"
            >
              Mobile App
            </Text>
          </Text>
          <a
            href="tel:+1(650)534-2071"
            onClick={() => mixpanel?.track("Website-Call Harshal's Phone")}
          >
            <Button
              width={281}
              backgroundColor="#ecf2f6"
              marginBottom={5}
              paddingY="8px"
              paddingX="18px"
              borderRadius={7}
              variant="solid"
            >
              <Image
                src="/assets/images/viber.png"
                alt="viber"
                objectFit="contain"
                width={'20px'}
              />
              <Text
                color="#401605"
                fontWeight="bold"
                width={200}
                textAlign="center"
              >
                {'+1 (650) 534-2071'}
              </Text>
            </Button>
          </a>
          <Stack direction="row" spacing={4}>
            <Button
              variant="solid"
              width={132}
              color="#ffffff"
              onClick={() => {
                mixpanel?.track('Website-PlayStoreClicked');
                window.open(
                  'https://play.google.com/store/apps/details?id=me.orderio.mobile'
                );
              }}
            >
              <Image
                src="/assets/images/google.png"
                alt="icon"
                objectFit="contain"
              />
            </Button>
            <Button
              color="#ffffff"
              variant="solid"
              width={132}
              onClick={() => {
                mixpanel?.track('Website-AppleStoreClicked');
                window.open(
                  'https://apps.apple.com/us/app/ekatree/id1661407311'
                );
              }}
            >
              <Image
                src="/assets/images/apple.png"
                alt="icon"
                objectFit="contain"
              />
            </Button>
          </Stack>
          {/* <Stack direction="row" marginTop="20px">
            <Box marginRight="10px">
              <Heading fontSize="30px" color="#FF5714" fontWeight="700">
                30%
              </Heading>
              <Text top={-10} fontSize="10px" color="#FFFFFF">
                cheaper than
              </Text>
            </Box>
            <Stack direction="row" width={120} justifyContent="space-between">
              <Image
                width={30}
                src="/assets/images/uber.png"
                alt="icon"
                objectFit="contain"
              />
              <Image
                width={30}
                src="/assets/images/grub.png"
                alt="icon2"
                objectFit="contain"
              />
              <Image
                width={30}
                src="/assets/images/other-app.png"
                alt="icon3"
                objectFit="contain"
              />
            </Stack>
          </Stack> */}
        </Box>
      </Container>
    </Box>
  );
}

export default Banner;
